import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { Searchbar } from '@components';
import Location from '@components/location';
import { staticImageLoader } from '@core/utils';
import { cartState, isLoggedIn } from '@recoil/atoms';
import { motion } from "framer-motion";
import { Html5QrcodeError } from "html5-qrcode/esm/core";
import { Bookmark, ChevronLeft, CircleUser, MapPin, Settings, ShoppingCart, UserRound, X } from 'lucide-react';
import dynamic from "next/dynamic";
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { signout } from '@api/authentication';
import PopupComponent from '@components/popup';
import AuthPopup from '@components/popup/auth';
import {
    LogOut,
    MapPinHouse, ScanLine, ShoppingBag,
    Wallet
} from 'lucide-react';
import { useRouter } from 'next/router';
import { createClient, Provider } from 'urql';

const QRScanner = dynamic(() => import("../qr").then((mod) => mod.QRScanner), {
  ssr: false,
});

interface Props {
  homeLink?: string;
  backText?: string;
}

export const Header: React.FC<Props> = ({ homeLink, backText }) => {
  const client = createClient({
    url: 'https://business.jhattse.com/graphql',
    exchanges: [],
  });
  const [userLoggedIn, setUserLoggedIn] = useRecoilState(isLoggedIn);
  const cart = useRecoilValue(cartState);
  const [ratio, setRatio] = useState(217 / 66);
  const [locationPopupShow, setLocationPopupShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [message, setMessage] = useState < string > ('Are you sure?');
  const [isQROpen, setQROpen] = useState(false);

  const router = useRouter();

  const onNewScanResult = (decodedText: string, decodedResult: any) => {
    // Handle the result here.
    console.debug(decodedResult);
    router.push(`${decodedText}`);
    setQROpen(false);
    return;
  }
  const onNewScanError = (errorMessage: string, error: Html5QrcodeError) => {
    console.debug(errorMessage);
    return;
  }

  const signOutCallback = () => {
    signout()
      .then((res) => { })
      .catch((err) => { })
      .finally(() => {
        setUserLoggedIn(false);
        localStorage.removeItem('token');
        localStorage.removeItem('profile');
        setShowModal(false);
        document.cookie =
          'access_token=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        router.push('/');
      });
  };

  useEffect(() => {
    setUserLoggedIn(localStorage.getItem('token')?.length > 0);
  }, [router.isReady]);

  return (
    <Provider value={client}>
      <div className="flex flex-col w-full">
        <div className="navbar w-full bg-neutral-100 flex flex-col justify-center sticky lt-sm:relative top-0 z-40 md:h-16 h-24 font-manrope">
          {/* Confirm Popup */}
          <PopupComponent
            showModal={showModal}
            setShowModal={setShowModal}
            title={message}
            message="Do you want to logout from the application."
            ActionFun={signOutCallback}
            closeBtnName="Cancel"
            btnName="Log Out"
          />

          <div className="flex flex-row items-center justify-items-stretch h-12 px-2">
            <div className="flex justify-between items-center md:gap-4 gap-2">
              {backText ? (
                <button
                  type="button"
                  className="md:hidden flex items-center gap-2 px-0"
                  onClick={() => router.back()}
                >
                  <ChevronLeft className="w-5 h-5 text-neutral-600" />
                  <span className="w-40 text-start text-base text-neutral-800 font-manrope font-semibold truncate overflow-ellipsis">
                    {backText}
                  </span>
                </button>
              ) : (
                <Link href={homeLink || '/'} prefetch={false}>
                  <Image
                    priority={true}
                    loader={staticImageLoader}
                    width={140}
                    height={140 / ratio}
                    src="public/jhattse-logo.svg?v=10"
                    alt="Jhattse logo"
                    className="md:hidden"
                    onLoadingComplete={({ naturalWidth, naturalHeight }) =>
                      setRatio(naturalWidth / naturalHeight)
                    }
                  />
                </Link>
              )}

              <Link href={homeLink || '/'} prefetch={false}>
                <Image
                  priority={true}
                  loader={staticImageLoader}
                  width={140}
                  height={140 / ratio}
                  src="public/jhattse-logo.svg"
                  alt="Jhattse logo"
                  className="hidden md:block"
                  onLoadingComplete={({ naturalWidth, naturalHeight }) =>
                    setRatio(naturalWidth / naturalHeight)
                  }
                />
              </Link>

              <div className="md:flex gap-1">
                <div className="md:flex gap-1 hidden md:block">
                  <button
                    title="Set Location"
                    onClick={() => setLocationPopupShow(true)}
                    className="whitespace-nowrap  text-neutral-50 text-4xl font-semibold px-2"
                  >
                    <MapPin size={24} className="text-brand-500" />
                  </button>

                  <div
                    className="flex flex-row md:flex-col cursor-pointer items-start"
                    onClick={() => setLocationPopupShow(true)}
                  >
                    <p className="text-xs font-semibold text-neutral-700">
                      Deliver to
                    </p>
                    {(typeof window !== 'undefined' && JSON.parse(localStorage.getItem('location'))?.Location?.name?.length > 0) ?
                      <span>
                        <p className="text-[14px] font-bold text-brand_indigo line-clamp-2">
                          {
                            JSON.parse(localStorage.getItem('location'))
                              ?.Location?.name
                          }
                        </p>
                      </span>
                      :
                      <span>
                        <p className="text-[14px] font-semibold text-brand-500">
                          SET LOCATION
                        </p>
                      </span>
                    }
                  </div>
                </div>
                {/* Location Popup */}
                <Location
                  showModal={locationPopupShow}
                  setShowModal={setLocationPopupShow}
                />
              </div>
            </div>
            <div className="m-auto max-w-[360px] w-2/5 md:w-1/2 md:visible invisible">
              <Searchbar />
            </div>

            <div className="flex flex-row items-center gap-2 pr-2">
              <div className="hidden md:flex font-manrope">
                {userLoggedIn ? (
                  <Menu>
                    <MenuButton
                      className="flex gap-1 items-center whitespace-nowrap text-neutral-700 text-lg px-2 font-semibold"
                      as={Button}
                    >
                      <div className="flex gap-1 items-center">
                        <CircleUser
                          size={30}
                          className="text-neutral-700 pr-1"
                        />{' '}
                        <span className="font-manrope text-base">Account</span>
                      </div>
                    </MenuButton>
                    <MenuList className="bg-neutral-50 py-2 px-4 rounded">
                      <MenuItem className="text-neutral-700">
                        <Link href={'/account/'} prefetch={false}>
                          <div className="flex gap-2 justify-between text-neutral-700 text-base items-center font-semibold p-2 font-manrope">
                            <span className="flex">
                              <UserRound size={18} className="text-base" />
                            </span>
                            <span>My Profile</span>
                          </div>
                        </Link>
                      </MenuItem>
                      <MenuItem className="text-neutral-700">
                        <Link href={'/order/me'} prefetch={false}>
                          <div className="flex gap-2 justify-between text-neutral-700 text-base items-center font-semibold p-2 font-manrope">
                            <span className="flex">
                              <ShoppingBag size={18} className="text-base" />
                            </span>
                            <span>My Orders</span>
                          </div>
                        </Link>
                      </MenuItem>
                      <MenuItem className="text-neutral-700">
                        <Link href={'/service/me'} prefetch={false}>
                          <div className="flex gap-2 justify-between text-neutral-700 text-base items-center font-semibold p-2 font-manrope">
                            <span className="flex">
                              <Bookmark size={18} className="text-base" />
                            </span>
                            <span>My Bookings</span>
                          </div>
                        </Link>
                      </MenuItem>
                      <MenuItem className="text-neutral-700">
                        <Link href={'/account/addresses'} prefetch={false}>
                          <div className="flex gap-2 justify-between text-neutral-700 text-base items-center font-semibold p-2 font-manrope">
                            <span className="flex">
                              <MapPinHouse
                                size={18}
                                className="text-base"
                              />
                            </span>
                            <span> My Addresses</span>
                          </div>
                        </Link>
                      </MenuItem>
                      <MenuItem className="text-neutral-700">
                        <Link href={'/account'} prefetch={false}>
                          <div className="flex gap-2 justify-between text-neutral-700 text-base items-center font-semibold p-2 font-manrope">
                            <span className="flex">
                              <Settings
                                size={18}
                                className="text-base"
                              />
                            </span>
                            <span>Settings</span>
                          </div>
                        </Link>
                      </MenuItem>
                      <MenuItem className="text-neutral-700">
                        <Link href={'/refer'} prefetch={false}>
                          <div className="flex gap-2 justify-between text-neutral-700 text-base items-center font-semibold p-2 font-manrope">
                            <span className="flex">
                              <Wallet size={18} className="text-base" />
                            </span>
                            <span> Refer and Earn</span>
                          </div>
                        </Link>
                      </MenuItem>
                      <MenuItem
                        className="text-neutral-700"
                        onClick={() => setShowModal(!showModal)}
                      >
                        <div className="flex gap-2 justify-between text-neutral-700 text-base items-center font-semibold p-2 font-manrope">
                          <span className="flex">
                            <LogOut className="text-base text-error-300" />
                          </span>
                          <span className="text-error-300">Log Out</span>
                        </div>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                ) : (
                  <>
                    <button
                      className="flex items-center gap-2 whitespace-nowrap text-neutral-50 text-lg font-semibold cursor-pointer"
                      onClick={() => {
                        setShowLoginModal(true);
                        console.debug('login clicked', showLoginModal);
                      }}
                    >
                      <CircleUser
                        size={30}
                        className="text-neutral-700"
                      />{' '}
                      <span className="text-base text-neutral-700">Login</span>
                    </button>
                  </>
                )}
              </div>
              <div className="flex items-center gap-2 relative py-2">
                <div className="md:hidden flex">
                  <div
                    onClick={() => setQROpen(!isQROpen)}
                    className="flex flex-col items-center"
                  >
                    <ScanLine size={28} />
                  </div>
                </div>
                <>
                  <Link href="/cart" className="z-30 cursor-pointer" prefetch={false}>
                    <button className="flex items-center gap-2 whitespace-nowrap text-neutral-50 text-lg px-2 font-semibold">
                      <ShoppingCart
                        size={28}
                        className="text-neutral-700"
                        aria-labelledby="cartdiv"
                      />{' '}
                      <span
                        className="font-manrope text-base lt-sm:hidden text-neutral-700"
                        id="cartdiv"
                      >
                        Cart
                      </span>
                    </button>
                  </Link>
                  <span
                    className={
                      cart?.size > 0
                        ? `absolute top-0 -right-2 flex justify-center items-center text-xs text-neutral-50 bg-success-500 rounded-full h-5 w-5`
                        : 'hidden'
                    }
                  >
                    {cart.size}
                  </span>
                </>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-center md:hidden md:w-1/3 px-2">
            <Searchbar />
          </div>
        </div>
        <div className="md:hidden bg-neutral-50 text-neutral-900 flex flex-row gap-2 py-2">
          <div className="flex">
            <button
              title="Set Location"
              onClick={() => setLocationPopupShow(true)}
              className="whitespace-nowrap text-neutral-900 font-semibold px-2"
            >
              <MapPin />
            </button>
            {typeof window !== 'undefined' && (
              <div
                className="font-manrope cursor-pointer select-none text-sm font-semibold text-neutral-700 flex gap-2"
                onClick={() => setLocationPopupShow(true)}
              >
                Deliver to{' '}
                {JSON.parse(localStorage.getItem('location'))?.Location
                  ?.name ? (
                  <span className="text-md font-bold text-brand_cerulean">
                    {
                      JSON.parse(localStorage.getItem('location'))?.Location
                        ?.name
                    }
                  </span>
                ) : (
                  <span className="text-[14px] font-bold text-brand-500">
                    SET LOCATION
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        <AuthPopup
          showModal={showLoginModal}
          setShowModal={setShowLoginModal}
        />
      </div>
      {
        isQROpen &&
        <div className="fixed -bottom-96 lt-sm:visible w-full z-40 flex flex-col justify-center">
          <motion.div className="grid justify-items-stretch"
            animate={isQROpen ? "open" : "closed"}
            variants={{
              open: { x: 0, y: "-115%" },
              closed: { y: "0%" },
            }}
            transition={{ ease: "easeOut", duration: 1 }}
            whileTap={{ scale: 0.99 }}>
            <div className="relative bg-brand_indigo flex flex-col gap-4 justify-center items-center w-full h-full z-20 rounded-t-2xl pt-2 pb-6">
              <div className="flex flex-row">
                <p className="text-neutral-50 text-center font-semibold text-xl">Scanning with <span className="text-brand-500">Jhattse</span></p>
                <div className="absolute top-4 right-4 text-neutral-300"><X onClick={(e) => { setQROpen(!isQROpen) }} /></div>
              </div>
              <QRScanner
                fps={6}
                qrbox={{ width: 320, height: 320 }}
                aspectRatio={1 / 1}
                disableFlip={false}
                verbose={true}
                qrCodeErrorCallback={onNewScanError}
                qrCodeSuccessCallback={onNewScanResult}
              />
            </div>
          </motion.div>
        </div>
      }
    </Provider>
  );
};
